import * as React from 'react'
import Link from 'gatsby-link'
import { Helmet } from 'react-helmet'

import './index.css'

const Header = () => (
  <div
    style={{
      background: '#0d47a1',
      marginBottom: '1.45rem',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          <img
            id="banner-logo"
            src="/assets/images/banner_logo_white.svg"
            alt="PhoneCompany.io"
            height="100" />
        </Link>
      </h1>
    </div>
  </div>
)

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  location: {
    pathname: string
  }
  children: any
}

const DefaultLayout = (props: DefaultLayoutProps) => (
  <div>
    <Helmet
      title="Put the Phone Company to work for you"
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    >
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
    </Helmet>
    <Header />
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
      }}
    >
      {props.children}
    </div>
  </div>
)

export default DefaultLayout
